import "./styles.css"

export const Divider = () => {
  return (
    <div className="divider">
        <div></div>
        <p>{`//`}</p>
        <div></div>
    </div>
  )
}